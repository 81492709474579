<template>
	<div>
		<div class="row mt-4 mb-4">
			<h1 class="col-sm-12 text-right">Carga de layout</h1>
		</div>

		<div class="row">
			<label for="layout" class="col-form-label col-sm-2">Archivo</label>
			<div class="col-sm-8"><input ref="archivo" type="file" name="layout" id="layout" class="form-control" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></div>
			<div class="col-sm-2"><button class="btn secondary-btn" @click="cargar_layout">Cargar layout</button></div>	
		</div>

		<fieldset>
			<legend>Output:</legend>
			<div class="output">
				<p v-for="(row, index) in output">{{ index }}: {{ row }}</p>
			</div>
		</fieldset>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'

	import api from '@/apps/garantias/api/otros'

	export default {
		components: {
			Modal
		}
		,data: function() {
			return {
				archivo: null
				,output: []
			}
		}
		,methods: {
			cargar_layout: async function() {
				let archivo = this.$refs.archivo;
				this.$log.info('archivo', archivo.files);
				if (!archivo || archivo.files.length == 0)
					return this.$helper.showMessage('Error','Debes seleccionar un documento','error','alert');

				try {
					let formdata = new FormData();
					formdata.append('archivo',archivo.files[0]);

					let res = (await api.cargar_layout(formdata)).data;
					this.output = res.output;
					this.$log.info('res', res);
				}catch(e) {
					this.$log.error('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
		}
	}
</script>

<style lang="scss">
	.output {
		min-height: 300px;
		overflow: auto;
	}
</style>